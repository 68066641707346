import { NumberOfDaysType } from 'config/enums/calendar.enum';
import { transformApiResponse } from 'redux/api/BaseApiTransform';
import { ApiArrayDataResponse, ArrayDataResponse } from 'types/models/ApiResponse';
import {
  AvailabilityDay,
  CoachAvailability,
  ExpertAvailability,
  NumberOfDays,
} from 'types/models/Availability/Availability';
import { gmtDayDateToCurrent } from 'utils/helpers';
import {
  ApiAvailability,
  ApiAvailabilityBody,
  ApiAvailabilityDay,
  ApiAvailabilitySlot,
  ApiAvailabilitySpecialDay,
  ApiExpertAvailability,
} from './availabilityApi.type';

const systemTimeZoneDiff = new Date().getTimezoneOffset() * 60;
const toPositiveTime = (time: number) => {
  switch (true) {
    case time < 0:
      return time + 86400;
    case time >= 86400:
      return time - 86400;
    default:
      return time;
  }
};
export const transformAvailability = (data: ApiAvailability) => {
  const numberOfDays: NumberOfDays = {
    number: data.availability_count,
    type: data.availability_unit as NumberOfDaysType,
  };

  const timeZoneId = data.time_zone;

  const weekDays: AvailabilityDay[] = data.days.slice(0, 7).map((day) => {
    const slots = day.slots.map((slot) => ({
      start: slot.start_time + systemTimeZoneDiff,
      end: slot.end_time + systemTimeZoneDiff,
    }));

    return {
      id: day.day,
      date: day.day,
      slots,
    };
  });

  const specificDays: AvailabilityDay[] = data.special.map((specialDay) => {
    const slots = specialDay.slots.map((slot) => ({
      date: gmtDayDateToCurrent(specialDay.date, slot.start_time),
      start: slot.start_time + systemTimeZoneDiff,
      end: slot.end_time + systemTimeZoneDiff,
    }));

    return {
      id: gmtDayDateToCurrent(specialDay.date),
      date: specialDay.date,
      slots,
    };
  });

  const coachAvailabilities: CoachAvailability = {
    timeZoneId,
    numberOfDays,
    weekDays,
    specificDays,
    adjustment: {
      before: data.time_before_event,
      after: data.time_after_event,
    },
  };

  return coachAvailabilities;
};

export const transformAvailabilitiesResponse = (response: {
  data: ApiAvailability;
}): CoachAvailability => {
  const { data } = response;

  return transformAvailability(data);
};

export const transformAvailabilityToApiBody = (
  availability: CoachAvailability,
): ApiAvailabilityBody => {
  const { numberOfDays, timeZoneId, weekDays, specificDays } = availability;

  const days: ApiAvailabilityDay[] = weekDays.map((weekDay) => {
    const slots: ApiAvailabilitySlot[] = weekDay.slots.map((slot) => ({
      start_time: toPositiveTime((slot.start as number) - systemTimeZoneDiff),
      end_time: toPositiveTime((slot.end as number) - systemTimeZoneDiff),
    }));

    return {
      day: weekDay.id,
      slots,
    };
  });

  const special: ApiAvailabilitySpecialDay[] = specificDays.map((specificDay) => {
    const slots: ApiAvailabilitySlot[] = specificDay.slots.map((slot) => ({
      start_time: toPositiveTime(slot.start as number),
      end_time: toPositiveTime(slot.end as number),
    }));

    return {
      date: specificDay.date - systemTimeZoneDiff,
      slots,
    };
  });

  const apiAvailabilityBody: ApiAvailabilityBody = {
    days,
    special,
    time_zone: timeZoneId as number,
    time_after_event: availability.adjustment.after || null,
    time_before_event: availability.adjustment.before || null,
    availability_count: numberOfDays.number as number,
    availability_unit: numberOfDays.type as number,
  };

  return apiAvailabilityBody;
};

export const transformExpertAvailabilitiesResponse = (
  response: ApiArrayDataResponse<ApiExpertAvailability>,
): ArrayDataResponse<ExpertAvailability> => {
  return {
    ...transformApiResponse(response),
    data: [
      ...response.data.map((availability) => {
        return {
          date: gmtDayDateToCurrent(availability.date),
          intervals: availability.intervals.map((interval) => {
            return {
              startDate: interval.start_time + systemTimeZoneDiff,
              endDate: interval.end_time + systemTimeZoneDiff,
            };
          }),
        };
      }),
    ],
  };
};
