import { createApi } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import Paginator from 'types/interfaces/Paginator';
import { injectParams } from 'utils/helpers/api-url';
import { paginatorToApiPagination } from 'utils/services/api.service';
import { baseQueryWithReAuth } from 'redux/api/BaseQueryConfig';
import { ApiArrayDataResponse } from 'types/models/ApiResponse';
import { ApiCoach, ApiCoachResponse, CoachRequestBody, CoachesOptions } from './coachApi.types';
import {
  transformCoachBody,
  transformCoachResponse,
  transformCoachRevenuesResponse,
  transformCoachesApiOptions,
  transformCoachesResponse,
  transformRevenuesCoachesApiOptions,
} from './coachApi.transform';
import { ApiCoachRevenues } from '../commonTypes/expenses';

export const coachApi = createApi({
  reducerPath: 'coachApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['Coaches', 'Availabilities', 'Stripe'],
  endpoints: (builder) => ({
    getCoaches: builder.query({
      query: (params: { paginator?: Paginator; options: CoachesOptions }) =>
        injectParams(ENDPOINTS.COACHES, {
          ...transformCoachesApiOptions(params.options),
          ...paginatorToApiPagination(params.paginator),
        }),
      transformResponse: (response: ApiArrayDataResponse<ApiCoach>) => {
        return transformCoachesResponse(response);
      },
      providesTags: ['Coaches'],
    }),
    getNumberOfCoaches: builder.query({
      query: (params) => ({
        url: ENDPOINTS.NUMBER_OF_COACHES.replace(':status', params.options.status),
        method: 'GET',
      }),
      providesTags: ['Coaches'],
    }),
    createCoach: builder.mutation({
      query: (body) => ({
        url: ENDPOINTS.ADD_COACH,
        method: 'POST',
        body: transformCoachBody(body),
      }),
      invalidatesTags: ['Coaches'],
    }),
    updateCoach: builder.mutation({
      query: ({ body, id }: { body: CoachRequestBody; id?: string }) => ({
        url: `${ENDPOINTS.UPDATE_COACH}/${id}/update`,
        method: 'PUT',
        body: transformCoachBody(body),
      }),
      invalidatesTags: ['Coaches'],
    }),
    reassingCoach: builder.mutation({
      query: (body) => ({
        url: ENDPOINTS.REASSIGN_COACH,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['Coaches'],
    }),
    suspendCoaches: builder.mutation({
      query: (body) => ({
        url: ENDPOINTS.SUSPEND_COACHES,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Coaches'],
    }),
    reactivateCoaches: builder.mutation({
      query: (body) => ({
        url: ENDPOINTS.REACTIVATE_COACHES,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Coaches'],
    }),
    deleteCoaches: builder.mutation({
      query: (body) => ({
        url: ENDPOINTS.DELETE_COACHES,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Coaches'],
    }),
    getCoach: builder.query({
      query: ({ id }) => `${ENDPOINTS.GET_COACH_BY_ID}/${id}`,
      transformResponse: (response: { data: ApiCoachResponse }) =>
        transformCoachResponse(response.data),
      providesTags: ['Availabilities', 'Coaches'],
    }),
    updateCoachState: builder.mutation({
      query: ({ body, id }) => ({
        url: `${ENDPOINTS.GET_COACH_BY_ID}/${id}/${ENDPOINTS.UPDATE_COACH_STATE}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Availabilities', 'Coaches'],
    }),
    createAccountStripe: builder.mutation({
      query: ({ body, id }) => ({
        url: `${ENDPOINTS.CREATE_ACCOUNT_STRIPE}/${id}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Availabilities', 'Coaches'],
    }),

    getCoachRevenues: builder.query({
      query: (params: { id?: string; months?: string }) =>
        injectParams(`${ENDPOINTS.COACHES_BO}/${params.id}/${ENDPOINTS.REVENUES}`, {
          ...transformRevenuesCoachesApiOptions(params?.months),
        }),
      transformResponse: (response: { data: ApiCoachRevenues }) => {
        return transformCoachRevenuesResponse(response.data);
      },
      providesTags: ['Coaches'],
    }),
    impersonateCoachCode: builder.mutation({
      query: (id: number) => ({
        url: `${ENDPOINTS.IMPERSONATE_COACH}/${id}`,
        method: 'POST',
      }),
      transformResponse: (response: { data: string }) => {
        return response.data;
      },
    }),
    createCoachNote: builder.mutation({
      query: ({ id, description }) => ({
        url: `${ENDPOINTS.COACHES_BO}/${id}/${ENDPOINTS.NOTES}`,
        method: 'POST',
        body: { description },
      }),
      invalidatesTags: ['Coaches'],
    }),
  }),
});

export const {
  useGetCoachesQuery,
  useCreateCoachMutation,
  useUpdateCoachMutation,
  useReassingCoachMutation,
  useSuspendCoachesMutation,
  useReactivateCoachesMutation,
  useDeleteCoachesMutation,
  useGetCoachQuery,
  useUpdateCoachStateMutation,
  useGetCoachRevenuesQuery,
  useGetNumberOfCoachesQuery,
  useCreateAccountStripeMutation,
  useImpersonateCoachCodeMutation,
  useCreateCoachNoteMutation,
} = coachApi;
