import { createApi } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import { baseQueryWithReAuth } from 'redux/api/BaseQueryConfig';
import { ASCTurnoverFeesApi, CoachRevenuesApi, DashboardStatisticsApi } from './dashboardApi.type';
import {
  transformASCTurnoverFeesResponse,
  transformCoachRevenuesResponse,
  transformDashboardStatisticsResponse,
  transformMonthsApiOptions,
} from './dashboardApi.transform';
import { injectParams } from 'utils/helpers';

export const dashboardApi = createApi({
  reducerPath: 'dashboardApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['dashboard'],
  endpoints: (builder) => ({
    getGlobalStatistics: builder.query({
      query: (params: { months?: string }) =>
        injectParams(`${ENDPOINTS.STATISTICS}`, {
          ...transformMonthsApiOptions(params.months),
        }),
      transformResponse: (response: { data: DashboardStatisticsApi }) => {
        return transformDashboardStatisticsResponse(response.data);
      },
      providesTags: ['dashboard'],
    }),
    getCoachRevenues: builder.query({
      query: (params: { months?: string }) =>
        injectParams(`${ENDPOINTS.COACH_REVENUES}`, {
          ...transformMonthsApiOptions(params.months),
        }),
      transformResponse: (response: { data: CoachRevenuesApi }) => {
        return transformCoachRevenuesResponse(response.data);
      },
      providesTags: ['dashboard'],
    }),
    getASCTurnoverFees: builder.query({
      query: (params: { months?: string }) =>
        injectParams(`${ENDPOINTS.ASC_TURNOVER_FEES}`, {
          ...transformMonthsApiOptions(params?.months),
        }),
      transformResponse: (response: { data: ASCTurnoverFeesApi }) => {
        return transformASCTurnoverFeesResponse(response.data);
      },

      providesTags: ['dashboard'],
    }),
  }),
});

export const { useGetGlobalStatisticsQuery, useGetCoachRevenuesQuery, useGetASCTurnoverFeesQuery } =
  dashboardApi;
