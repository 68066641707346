export const reschedule = {
  monday: 'Lundi',
  tuesday: 'Mardi',
  wednesday: 'Mercredi',
  thursday: 'Jeudi',
  friday: 'Vendredi',
  select_date: 'Sélectionnez une date et un créneau horaire',
  add_new_link: 'Ajouter un nouveau lien de la visio',
  placeholder: 'lien visio',
  fieldName: 'Visio',
  pick_date:
    'Vous devez impérativement inclure un lien vers la visioconférence et choisir un créneau horaire pour le rendez-vous.',
  link_required: 'Le lien est requis',
  invalid_url: "L'url est invalide",
  reschedule: 'Réplanifer',
  success_pick_date: 'Réplanification faite avec succès',
  appointment_title: 'Planifier un rendez-vous',
  add_link_title: 'Intégrer un lien pour la visioconférence',
  add_success_rdv: 'Le lien du RDV a été ajouté avec succès',
  required_link_rdv: 'Le lien est requis',
  week_from: 'Semaine du',
  to: 'au',
};
