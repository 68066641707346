import { GlobalVariables } from 'config/constant';
import { SECONDS_IN_TWO_DAYS } from 'config/constant/constants';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import duration from 'dayjs/plugin/duration';
import utcPlugin from 'dayjs/plugin/utc';
import { isUndefined } from 'lodash';
dayjs.extend(utcPlugin);
dayjs.extend(duration);

export const getDayOfWeekNumber = (date?: number) => {
  return timestampToDayjs(date ?? dateToTimestamp()).day();
};
export const timestampToDate = (timestamp: number): Date => {
  return dayjs.unix(timestamp).toDate();
};

export const dateToTimestamp = (date?: Date | string): number => {
  return dayjs(date).unix();
};

export const timestampToAdapterDate = (timestamp?: number): Dayjs => {
  return dayjs(timestamp);
};

export const minuteAndHourToTimestamp = (minute: number, hour: number, date: number): number => {
  return dayjs(date).hour(hour).minute(minute).valueOf();
};
export const timestampToDayjs = (timestamp: number, isSeconds?: boolean): Dayjs => {
  return dayjs.unix(isSeconds ? dayjs().utc().startOf('day').unix() + timestamp : timestamp);
};

export const dayjsToTimestamp = (date: Dayjs): number => {
  return date.unix();
};
export const stringTimeToTimestamp = (time: string, date?: number): number => {
  const hour = parseInt(time.split(':')[0], 10);
  const minute = parseInt(time.split(':')[1], 10);
  return minuteAndHourToTimestamp(minute, hour, date || 0);
};

export const getTimeSeconds = (timestamp: number, utcOffset?: number): number => {
  const minutes =
    dayjs
      .unix(timestamp)
      .utcOffset(utcOffset ?? 0)
      .minute() * 60;
  const hours =
    dayjs
      .unix(timestamp)
      .utcOffset(utcOffset ?? 0)
      .hour() * 3600;
  return minutes + hours;
};

export const formatDate = (timestamp: number, format: string, local?: string): string => {
  const isSeconds = timestamp < SECONDS_IN_TWO_DAYS;
  return dayjs
    .unix(isSeconds ? dayjs().utc().startOf('day').unix() + timestamp : timestamp)
    .locale(local ?? 'en')
    .format(format);
};

export const addDayToDate = (date: number, days: number): number => {
  return dayjs.unix(date).add(days, 'day').unix();
};

export const addHoursToDate = (date: number, hours: number): number => {
  return dayjs.unix(date).add(hours, 'hour').unix();
};

export const addMinutesToDate = (date: number, minutes: number): number => {
  return dayjs.unix(date).add(minutes, 'minute').unix();
};

export const addMonthsToDate = (date: number, months: number): number => {
  return dayjs.unix(date).add(months, 'month').unix();
};

export const addWeeksToDate = (date: number, weeks: number): number => {
  return dayjs.unix(date).add(weeks, 'week').unix();
};

export const isAfter = (date: number | string, dateToCompare: number | string): boolean => {
  return dayjs(date).isAfter(dayjs(dateToCompare));
};

export const isBeforeCurrentDate = (date: number | string): boolean => {
  return dayjs(date).isBefore(dayjs().unix());
};

export const isAfterCurrentDate = (date: number | string): boolean => {
  return dayjs(date).isAfter(dayjs().unix());
};

export const isBefore = (date: number, dateToCompare: number): boolean => {
  return dayjs(date).isBefore(dateToCompare);
};

export const isSame = (date: number, dateToCompare: number, option?: DateDiff): boolean => {
  return dayjs.unix(date).isSame(dayjs.unix(dateToCompare), option);
};

export const calculateDuration = (start: number, end: number): number => {
  return dayjs(end).diff(dayjs(start), 'minute');
};

export const getDayDate = (date?: number): number => {
  return dayjs
    .unix(date ?? dateToTimestamp())
    .startOf('day')
    .unix();
};

export const getHour = (date: number): number => {
  return dayjs(date).hour();
};

export const getMinute = (date: number): number => {
  return dayjs(date).minute();
};

export const getDifferenceInMinutes = (start: number, end: number): number => {
  return dayjs(end).diff(dayjs(start), 'minute');
};

export const getStartOfMonth = (date: number): number => {
  return dayjs.unix(date).startOf('month').unix();
};

export const getStartOfWeek = (date: number): number => {
  return dayjs.unix(date).startOf('week').unix();
};
export const getEndOfWeek = (date: number, fullWeek?: boolean): number => {
  //  second to last day of week
  return dayjs
    .unix(date)
    .endOf('week')
    .subtract(fullWeek ? 0 : 2, 'day')
    .unix();
};

export const findFirstDayOfWeek = (currentDate: Date, firstDayOfWeek = 0) => {
  const date = new Date(currentDate.getTime());
  const day = date.getDay();
  const diff = (day < firstDayOfWeek ? 7 : 0) + day - firstDayOfWeek - 1;
  date.setDate(date.getDate() - diff);
  return date;
};

export const findLastDayOfWeek = (currentDate: Date, lastDayOfWeek = 6) => {
  const date = new Date(currentDate.getTime());
  const day = date.getDay();
  const diff = (day < lastDayOfWeek ? lastDayOfWeek - day : 7) - 1;
  date.setDate(date.getDate() + diff);
  return date;
};

export const isBetween = (date: number, start: number, end: number): boolean => {
  const startTimeWithoutSeconds = dayjs(start).startOf('minute').valueOf();
  const endTimeWithoutSeconds = dayjs(end).startOf('minute').valueOf();
  return (
    dayjs(date).isAfter(startTimeWithoutSeconds) && dayjs(date).isBefore(endTimeWithoutSeconds)
  );
};

type DateDiff = 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year';
export const dateDiff = (date1: number, date2: number, between: DateDiff): number => {
  return dayjs(date1).diff(dayjs(date2), between);
};

export const calculateMeetDate = (
  firstDay: Date,
  day: number,
  firstHour?: number,
  lastHour?: number,
) => {
  const date = new Date(firstDay);
  const daySelected = new Date(date.setDate(date.getDate() + day - 1));
  if (firstHour) {
    return new Date(daySelected.setHours(firstHour, 0, 0));
  }
  if (lastHour) {
    return new Date(daySelected.setHours(lastHour, 0, 0));
  }
};

export const dateToFormatString = (date: Date | undefined) => {
  return date?.toLocaleString('fr-FR', {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });
};

export const hoursToString = (date: Date | undefined) => {
  if (date) {
    return (
      (date?.getHours() < 10 ? '0' : '') +
      date?.getHours() +
      ':' +
      (date?.getMinutes() < 10 ? '0' : '') +
      date?.getMinutes()
    );
  } else {
    return '';
  }
};

export const roundToNearest15Minutes = (timestamp: number): number => {
  const parsedTime = dayjs(timestamp);
  const minutes = parsedTime.minute();
  const roundedMinutes = Math.round(minutes / 15) * 15;
  const roundedTime = parsedTime.minute(roundedMinutes);
  // return the timestamp value
  return roundedTime.valueOf();
};

export const isValidTimeFormat = (timeString: string) => {
  return dayjs(timeString, GlobalVariables.Date.TimeFormat, true).isValid();
};

// TODO : remove it
export const getDifferenceInYears = (startDate: number): number => {
  return dayjs(new Date()).diff(dayjs.unix(startDate), 'year');
};

export const calculateAgeFromBirthDate = (timestamp: number): number => {
  const birthDate = dayjs.unix(timestamp);
  const currentDate = dayjs();
  return currentDate.diff(birthDate, 'year');
};

export const getDate = (timestamp: number): number => {
  return dayjs(timestamp).date();
};

export const getMonth = (timestamp: number): number => {
  return dayjs(timestamp).month() + 1;
};

export const getYear = (timestamp: number): number => {
  return dayjs(timestamp).year();
};

export const getTimezoneOffsetInHours = () => {
  return -1 * (new Date().getTimezoneOffset() / 60);
};

export const toPositiveTime = (time: number) => {
  switch (true) {
    case time < 0:
      return time + 86400;
    case time >= 86400:
      return time - 86400;
    default:
      return time;
  }
};

export const toPositiveMinutes = (time: number) => {
  switch (true) {
    case time < 0:
      return time + 1440;
    case time >= 1440:
      return time - 1440;
    default:
      return time;
  }
};

export const compareTimes = (start: number, end: number) => {
  const systemTimeZoneDiff = new Date().getTimezoneOffset() * 60;
  const systemStartTime = toPositiveTime(start - systemTimeZoneDiff);
  const systemEndTime = toPositiveTime(end - systemTimeZoneDiff);

  return systemStartTime >= systemEndTime;
};

export const dayDateToGMT = (date: number, start?: number): number => {
  let dayOffset = 0;
  const systemTimeZoneDiff = new Date().getTimezoneOffset() * 60;
  if (!isUndefined(start)) {
    const timeOffset = start - systemTimeZoneDiff;
    dayOffset = timeOffset < 0 ? 86400 : timeOffset >= 86400 ? -86400 : 0;
  }
  return date - systemTimeZoneDiff + dayOffset;
};
export const setNextMonday = (currentDate: number) => {
  if (dayjs.unix(currentDate).day() >= 5 && dayjs.unix(currentDate).hour() > 18) {
    const currentDay = dayjs.unix(currentDate).day();
    const nextMonday = (1 - currentDay + 7) % 7;
    dayjs.unix(currentDate).add(nextMonday, 'day');
  }
};

export const gmtDayDateToCurrent = (date: number, start?: number): number => {
  if (isUndefined(start)) {
    const systemTimeZoneDiff = new Date().getTimezoneOffset() * 60;
    return date + systemTimeZoneDiff;
  } else {
    return getDayDate(date + start);
  }
};

export const timestampToFormatString = (timestamp: number, locale: string): string => {
  const date = dayjs.unix(timestamp).locale(locale);
  return date.format('dddd D MMMM YYYY - HH[h]mm');
};
