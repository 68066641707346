export const DEFAULT_REFERRAL_CODE = 0;
export const DEFAULT_COUNTRY_CODE = 'FR';
export const phoneFormat = {
  regex: /(\d{2})(\d{1})(\d{2})(\d{2})(\d{2})(\d{2})/,
  Format: '$1 $2 $3 $4 $5 $6',
};
export const MAX_COUNT_DISPLAY = '+99';
export const MAX_PERCENT = '100%';

// Calendar Min / max Timeline
export const minTime = '00:00';
export const maxTime = '25:00';

export const FR = 'FR: ';
export const EN = 'EN: ';
export const TEL = 'tel:';

// stripe keys
export const EXTERNAL_ACCOUNT = 'external_account';
export const BUSINESS_PROFILE = 'business_profile';
export const INDIVIDUAL = 'individual';
export const PERSON = 'person_';
export const REPRESENTATIVE = 'representative';

// stripe subSection keys
export const SUBSECTIONS = {
  DATE_OF_BIRTH: 'dob',
  // TODO: Complete the others SubSections..
};
// Countries
export const FR_CODE = 'FR';
export enum StatusProCardEnum {
  YES = 1,
  IN_PROGRESS = 2,
  NO = 3,
}
export const OPTIONS_CARD_STATUS = [
  {
    value: StatusProCardEnum.YES,
    label: 'common.yes',
  },
  {
    value: StatusProCardEnum.IN_PROGRESS,
    label: 'coaches.carte_progress',
  },
  {
    value: StatusProCardEnum.NO,
    label: 'common.no',
  },
];

export const QUANTITY_SOLD = 'quantity_sold';

export const SECONDS_IN_TWO_DAYS = 172800;
