import { transformApiResponse } from 'redux/api/BaseApiTransform';
import {
  TrackingList,
  TrackingListApi,
  TrackingOptions,
  trackingById,
  trackingByIdFromApi,
} from 'redux/api/marketing/tracking/tracking.type';
import { ApiArrayDataResponse, ArrayDataResponse } from 'types/models/ApiResponse';

export const transformTrackingListOptions = (options?: TrackingOptions) => {
  return {
    start_date: options?.startDate,
    end_date: options?.endDate,
  };
};
export const transformTrackingListResponse = (
  response: ApiArrayDataResponse<TrackingListApi>,
): ArrayDataResponse<TrackingList> => {
  return {
    ...transformApiResponse(response),
    data: response.data.map((tracking) => {
      return {
        id: tracking.id,
        title: tracking.title,
        target: tracking.target,
        url: tracking.url,
        trackingLink: tracking.tracking_link,
        updatedAt: tracking.updated_at,
        createdAt: tracking.created_at,
        deletedAt: tracking.deleted_at,
        totalClickCount: tracking.total_click_count,
        totalTransformationCount: tracking.total_transformation_count,
        conversionCount: tracking.conversion_count,
        confirmationCount: tracking.confirmation_count,
      };
    }),
  };
};

export const transformTrackingLinkResponse = (response: trackingByIdFromApi): trackingById => {
  return {
    id: response.id,
    target: response.target,
    title: response.title,
    trackingLink: response.tracking_link,
    url: response.url,
    clickCountBingAds: response.click_count_bing_ads,
    clickCountGoogleAds: response.click_count_google_ads,
    clickCountInstagram: response.click_count_linkedin,
    clickCountLinkedin: response.click_count_linkedin,
    clickCountPinterest: response.click_count_pinterest,
    clickCountSnapchat: response.click_count_snapchat,
    clickCountTiktok: response.click_count_tiktok,
    clickCountTwitter: response.click_count_twitter,
    createdAt: response.created_at,
  };
};

export const transformGeneratedShortLinkResponse = (data: string): { shortLink: string } => {
  return {
    shortLink: data,
  };
};
