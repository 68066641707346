import { transformApiResponse } from 'redux/api/BaseApiTransform';
import { transformApiMediaType } from 'redux/api/commonTypes/file';
import { mapReviews } from 'redux/api/notice/noticeApi.decoder';
import {
  Details,
  DetailsFromApi,
  NoticesOptions,
  Review,
  ReviewsFromApi,
} from 'redux/api/notice/noticeApi.type';
import { ApiArrayDataResponse, ArrayDataResponse } from 'types/models/ApiResponse';

export const transformReviewResponse = (
  response: ApiArrayDataResponse<ReviewsFromApi>,
): ArrayDataResponse<Review> => {
  return {
    ...transformApiResponse(response),
    data: response.data.map(mapReviews),
  };
};

export const transformNoticeDetailsResponse = (response: DetailsFromApi): Details => {
  return {
    id: response.id,
    coach: {
      id: response.coach.id,
      firstName: response.coach.first_name,
      lastName: response.coach.last_name,
      photo: transformApiMediaType(response.coach.photo),
      rating: response.coach.rating,
      email: response.coach.email,
    },
    user: {
      id: response?.user?.id,
      firstName: response?.user?.first_name,
      lastName: response?.user?.last_name,
      profilePicture: transformApiMediaType(response?.user?.profile_photo),
      email: response?.user?.email,
    },
    product: {
      name: response.product.name,
      type: response.product.type,
      photo: response.product.photo,
    },
    timeSlot: {
      id: response?.time_slot?.id,
      date: response?.time_slot?.date,
      dateTimestamp: response?.time_slot?.date_timestamp,
      startTime: response?.time_slot?.start_time,
      endTime: response?.time_slot?.end_time,
      location: {
        id: response?.time_slot?.location?.id,
        picture: response?.time_slot?.location?.picture,
        name: response?.time_slot?.location?.name,
      },
    },
    title: response.title,
    rating: response.rating,
    sessions: response.sessions,
    content: response.content,
    createdAt: response.created_at,
    updatedAt: response.updated_at,
  };
};

export const transformReviewsApiOptions = (options?: NoticesOptions) => {
  return {
    start_date: options?.startDate,
    end_date: options?.endDate,
  };
};
