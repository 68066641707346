import { Group } from 'features/Translation/Types/models/Group';
import { Key } from 'features/Translation/Types/models/Key';
import { Language } from 'features/Translation/Types/models/Language';
import { Platform } from 'features/Translation/Types/models/Platform';
import { Section } from 'features/Translation/Types/models/Section';
import {
  CreateGroup,
  CreateGroupResponse,
  CreateKeyRequest,
  CreateLanguageRequest,
  CreateSection,
  EncodeCreateGroup,
  EncodedCreateKeyRequest,
  EncodedCreateSection,
  GroupApi,
  KeyApi,
  LanguageApi,
  PlatformApi,
  SectionApi,
  TranslationApi,
  TranslationKeyValue,
  UploadTranslationRequest,
} from 'redux/api/translation/translationApi.type';
import { ApiArrayDataResponse, ArrayDataResponse } from 'types/models/ApiResponse';
import { transformApiResponse } from '../BaseApiTransform';
import { Translation } from 'features/Translation/Types/models/Translation';

const mapOverSectionGroups = (groups: GroupApi[]): Group[] => {
  if (groups && groups.length > 0) {
    return groups.map((group) => ({
      id: group.id,
      name: group.name,
      parentId: group.parent_id,
      parentType: group.parent_type,
      createdAt: group.created_at,
      updatedAt: group.updated_at,
      deletedAt: group.deleted_at,
      groups: mapOverSectionGroups(group.groups),
    }));
  }

  return [];
};

const mapOverSections = (sections: SectionApi[]): Section[] => {
  if (sections && sections.length > 0) {
    return sections.map((section) => ({
      id: section.id,
      name: section.name,
      platformId: section.platform_id,
      type: section.type,
      createdAt: section.created_at,
      updatedAt: section.updated_at,
      deletedAt: section.deleted_at,
      groups: mapOverSectionGroups(section.groups),
    }));
  }

  return [];
};

export const transformListingPlatformsResponse = (platformsApi: PlatformApi[]): Platform[] => {
  const platforms: Platform[] = platformsApi.map((platform) => ({
    id: platform.id,
    name: platform.name,
    code: platform.code,
    createdAt: platform.created_at,
    updatedAt: platform.updated_at,
    deletedAt: platform.deleted_at,
    sections: mapOverSections(platform.sections),
  }));

  return platforms;
};

export const transformCreatePlatformResponse = (platformApi: PlatformApi): Platform => {
  const platform: Platform = {
    id: platformApi.id,
    name: platformApi.name,
    code: platformApi.code,
    createdAt: platformApi.created_at ?? '',
    updatedAt: platformApi.updated_at ?? '',
    deletedAt: platformApi.deleted_at ?? '',
    sections: [],
  };

  return platform;
};

export const transformCreateSectionResponse = (sectionApi: SectionApi): Section => {
  const section: Section = {
    id: sectionApi.id,
    name: sectionApi.name,
    platformId: sectionApi.platform_id,
    type: sectionApi.type,
    createdAt: sectionApi.created_at ?? '',
    updatedAt: sectionApi.updated_at ?? '',
    deletedAt: sectionApi.deleted_at ?? '',
    groups: [],
  };

  return section;
};

export const encodeCreateSectionRequest = (data: CreateSection): EncodedCreateSection => {
  return {
    name: data.name,
    platform_id: data.platformId,
  };
};

export const encodeCreateGroupRequest = (data: CreateGroup): EncodeCreateGroup => {
  return {
    name: data.name,
    parent_type: data.parentType,
    parent_id: data.parentId,
  };
};

export const transformCreateGroupResponse = (response: CreateGroupResponse): Group => {
  const { data } = response;

  const group: Group = {
    id: data.id,
    name: data.name,
    parentId: data.parent_id,
    parentType: data.parent_type,
    createdAt: data.created_at ?? '',
    updatedAt: data.updated_at ?? '',
    deletedAt: data.deleted_at ?? '',
    groups: [],
  };

  return group;
};

export const transformListingLanguages = (languages: LanguageApi[]): Language[] => {
  const languagesList: Language[] = languages.map((language) => ({
    id: language.id,
    name: language.name,
    countryCode: language.country_code,
    flag: language.flag,
  }));

  return languagesList;
};

export const transformListingTranslations = (data: TranslationApi[]): Translation[] => {
  return data.map((translation) => ({
    id: translation.id,
    keyId: translation.key_id,
    languageId: translation.language_id,
    value: translation.value,
  }));
};

export const transformListingKeys = (
  response: ApiArrayDataResponse<KeyApi>,
): ArrayDataResponse<Key> => {
  return {
    ...transformApiResponse(response),
    data: response.data.map((key) => ({
      id: key.id,
      name: key.name,
      description: key.description ?? key.path,
      isConstant: false,
      parentId: key.parent_id,
      parentType: key.parent_type,
      createdAt: key.created_at,
      updatedAt: key.updated_at,
      translations: transformListingTranslations(key.translations),
    })),
  };
};

export const encodeCreateKeyRequest = (body: CreateKeyRequest): EncodedCreateKeyRequest => {
  const { formValues, parentId, parentType, platformId } = body;
  const { name, description } = formValues;

  const languageValues: TranslationKeyValue[] = [];

  // Extract language values based on keys like 'language-1', 'language-2', etc.
  Object.keys(formValues).forEach((key) => {
    if (key.startsWith('language-')) {
      const languageId = parseInt(key.split('-')[1]);
      const value = formValues[key];
      // push a new object into the languagesValue Array
      languageValues.push({ language_id: languageId, value });
    }
  });

  return {
    name,
    description,
    parent_id: parentId,
    parent_type: parentType,
    values: languageValues,
    platform_id: platformId,
  };
};

export const encodeCreateLanguageRequest = (body: CreateLanguageRequest): FormData => {
  const formData = new FormData();

  const formBody = {
    name: body.name,
    country_code: body.countryCode,
    flag: body.flag,
    file_deleted: body.method && !body.hasFlag ? 1 : undefined,
  };

  Object.keys(formBody).forEach((key) => {
    const value = formBody[key as keyof typeof formBody];

    if (value) {
      formData.append(key, value as Blob);
    }
  });

  return formData;
};

export const encodeUploadTranslationRequest = (body: UploadTranslationRequest): FormData => {
  const formData = new FormData();

  const formBody = {
    file: body.file,
    platform_id: body.platformId,
  };

  Object.keys(formBody).forEach((key) => {
    const value = formBody[key as keyof typeof formBody];

    formData.append(key, value as Blob);
  });

  return formData;
};
