import {
  ApiArrayDataResponse,
  ApiBODataResponse,
  ArrayDataResponse,
} from 'types/models/ApiResponse';
import { transformApiResponse } from '../BaseApiTransform';
import { Account, AccountsApi } from './accountsApi.type';
import { toCamelCase } from 'utils/helpers/object.helpers';

export const transformAccountsResponse = (
  response: ApiArrayDataResponse<AccountsApi>,
): ArrayDataResponse<Account> => {
  return {
    ...transformApiResponse(response),
    data: response.data.map((apiAccount) => {
      return {
        id: apiAccount.id,
        firstName: apiAccount.first_name,
        lastName: apiAccount.last_name,
        email: apiAccount.email,
        identifier: apiAccount.identifier,
        roleId: apiAccount.role_id,
        profilePicture: apiAccount.profile_picture,
        coachsAcceptedCount: apiAccount.coaches_accepted_count,
        coachsRefusedCount: apiAccount.coaches_refused_count,
        coachsWaitingCount: apiAccount.coaches_waiting_count,
        totalCoaches: apiAccount.total_coaches,
        createdAt: apiAccount.created_at,
        updatedAt: apiAccount.updated_at,
      };
    }),
  };
};

export const transformAccountResponse = (apiAccount: AccountsApi): Account => {
  return {
    id: apiAccount.id,
    firstName: apiAccount.first_name,
    lastName: apiAccount.last_name,
    email: apiAccount.email,
    identifier: apiAccount.identifier,
    roleId: apiAccount.role_id,
    profilePicture: apiAccount.profile_picture,
    coachsAcceptedCount: apiAccount.coaches_accepted_count,
    coachsRefusedCount: apiAccount.coaches_refused_count,
    coachsWaitingCount: apiAccount.coaches_waiting_count,
    totalCoaches: apiAccount.total_coaches,
    createdAt: apiAccount.created_at,
    updatedAt: apiAccount.updated_at,
  };
};

export const transformAccountsApiBodyResponse = (response: ApiBODataResponse) => {
  const dataResponse = response;
  return {
    ...toCamelCase(dataResponse.data),
    data: {
      firstName: dataResponse.data.first_name,
      lastName: dataResponse.data.last_name,
      email: dataResponse.data.email,
      identifier: dataResponse.data.identifier,
      roleID: dataResponse.data.role_id,
      profilePicture: dataResponse.data.profile_picture,
      coachsAcceptedCount: dataResponse.data.coachs_accepted_count,
      coachsRefusedCount: dataResponse.data.coachs_refused_count,
      coachsWaitingCount: dataResponse.data.coachs_waiting_count,
      createdAt: dataResponse.data.created_at,
      updatedAt: dataResponse.data.updated_at,
    },
  };
};
