import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import alertSlice from './slices/alerts/alerts';
import { clientApi } from './api/client/clientApi';
import { placeApi } from './api/place/placeApi';
import inscriptionSlice from './slices/Inscription/inscriptionSlice';
import { authApi } from './api/auth/login/loginApi';
import { registerApi } from './api/auth/register/registerApi';
import authSlice from './slices/Auth/authSlice';
import { additionalProductApi } from './api/additionalProduct/additionalProductApi';
import { accountsApi } from './api/accounts/accountsApi';
import appSlice from './slices/app/appSlice';
import { eventApi } from './api/event/eventApi';
import { userApi } from './api/user/userApi';
import { coachApi } from './api/coach/coachApi';
import { activitiesApi } from 'redux/api/activities/activitiesApi';
import { countriesApi } from 'redux/api/countries/countriesApi';
import { commandApi } from './api/command/commandApi';
import { availabilityApi } from 'redux/api/availability/availabilityApi';
import { citiesApi } from './api/cities/citiesApi';
import { mediaApi } from './api/media/mediaApi';
import { productsApi } from 'redux/api/products/products';
import { groupApi } from 'redux/api/coach/groupOfCoaches/groupOfCoachesApi';
import { noticeApi } from 'redux/api/notice/noticeApi';
import { exerciseApi } from './api/exercise/exerciseApi';
import { bestProductsApi } from './api/coach/bestProducts/bestProducts';
import { invoicesApi } from './api/coach/invoices/invoices';
import { nextCourseApi } from './api/user/nextCourse/nextCourseApi';
import { dashboardApi } from './api/dashboard/dashboardApi';
import { GoalsFormApi } from 'redux/api/user/UserForm/GoalsUserForm/goalsFormApi';
import { LevelFormApi } from 'redux/api/user/UserForm/LevelUserForm/levelFormApi';
import { popupApi } from './api/marketing/popup/popupApi';
import { ActivityUserForm } from 'redux/api/user/UserForm/ActivityUserForm/activityUserFormApi';
import { hoursFormApi } from 'redux/api/user/UserForm/HoursUserForm/hoursFormApi';
import { boostingApi } from './api/marketing/boosting/boostingApi';
import { trackingApi } from 'redux/api/marketing/tracking/trackingApi';
import { notificationApi } from './api/marketing/notification/notificationApi';
import { stripeApi } from 'redux/api/stripe/stripeApi';
import { placeStripeApi } from 'redux/api/stripe/placeStripeApi';
import { translationApi } from './api/translation/translationApi';
import translationSlice from './slices/translation/translation';
import { languageApi } from 'redux/api/languages/languageApi';
import placeFiltersSlice from './slices/placeFilters/placeFiltersSlice';
import userFormSlice from './slices/userForm/userFormSlice';
import { campaignApi } from './api/marketing/campaign/campaignApi';

const rootReducer = combineReducers({
  appReducer: appSlice,
  inscriptionReducer: inscriptionSlice,
  authReducer: authSlice,
  alertReducer: alertSlice,
  translationReducer: translationSlice,
  placeFiltersReducer: placeFiltersSlice,
  userFormReducer: userFormSlice,
  [languageApi.reducerPath]: languageApi.reducer,
  [clientApi.reducerPath]: clientApi.reducer,
  [productsApi.reducerPath]: productsApi.reducer,
  [placeApi.reducerPath]: placeApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [registerApi.reducerPath]: registerApi.reducer,
  [additionalProductApi.reducerPath]: additionalProductApi.reducer,
  [eventApi.reducerPath]: eventApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [coachApi.reducerPath]: coachApi.reducer,
  [activitiesApi.reducerPath]: activitiesApi.reducer,
  [countriesApi.reducerPath]: countriesApi.reducer,
  [commandApi.reducerPath]: commandApi.reducer,
  [accountsApi.reducerPath]: accountsApi.reducer,
  [availabilityApi.reducerPath]: availabilityApi.reducer,
  [citiesApi.reducerPath]: citiesApi.reducer,
  [mediaApi.reducerPath]: mediaApi.reducer,
  [productsApi.reducerPath]: productsApi.reducer,
  [groupApi.reducerPath]: groupApi.reducer,
  [noticeApi.reducerPath]: noticeApi.reducer,
  [exerciseApi.reducerPath]: exerciseApi.reducer,
  [bestProductsApi.reducerPath]: bestProductsApi.reducer,
  [invoicesApi.reducerPath]: invoicesApi.reducer,
  [nextCourseApi.reducerPath]: nextCourseApi.reducer,
  [dashboardApi.reducerPath]: dashboardApi.reducer,
  [GoalsFormApi.reducerPath]: GoalsFormApi.reducer,
  [LevelFormApi.reducerPath]: LevelFormApi.reducer,
  [popupApi.reducerPath]: popupApi.reducer,
  [ActivityUserForm.reducerPath]: ActivityUserForm.reducer,
  [hoursFormApi.reducerPath]: hoursFormApi.reducer,
  [boostingApi.reducerPath]: boostingApi.reducer,
  [trackingApi.reducerPath]: trackingApi.reducer,
  [notificationApi.reducerPath]: notificationApi.reducer,
  [stripeApi.reducerPath]: stripeApi.reducer,
  [placeStripeApi.reducerPath]: placeStripeApi.reducer,
  [translationApi.reducerPath]: translationApi.reducer,
  [campaignApi.reducerPath]: campaignApi.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat([
      clientApi.middleware,
      placeApi.middleware,
      additionalProductApi.middleware,
      eventApi.middleware,
      userApi.middleware,
      coachApi.middleware,
      activitiesApi.middleware,
      countriesApi.middleware,
      commandApi.middleware,
      accountsApi.middleware,
      availabilityApi.middleware,
      citiesApi.middleware,
      productsApi.middleware,
      groupApi.middleware,
      noticeApi.middleware,
      exerciseApi.middleware,
      bestProductsApi.middleware,
      invoicesApi.middleware,
      nextCourseApi.middleware,
      dashboardApi.middleware,
      GoalsFormApi.middleware,
      LevelFormApi.middleware,
      popupApi.middleware,
      ActivityUserForm.middleware,
      hoursFormApi.middleware,
      boostingApi.middleware,
      trackingApi.middleware,
      notificationApi.middleware,
      stripeApi.middleware,
      placeStripeApi.middleware,
      translationApi.middleware,
      mediaApi.middleware,
      languageApi.middleware,
      campaignApi.middleware,
    ]),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
