export const marketing = {
  // sections
  marketing: 'Marketing',
  trackingLink: 'Liens de tracking',
  promoCodes: 'Codes promos',
  coachAffiliation: 'Affiliation coach',
  sendNotification: 'Envoi notification',
  homeUser: 'Accueil utilisateur',
  popUp: 'Pop-up',
  subTitle: 'Liste des liens de tracking',
  search_placeholder: 'Rechercher lien de tracking',
  // table
  title: 'Titre',
  name_campaign: 'Nom campagne',
  title_campaign: 'Titre campagne',
  startDate: 'Date début',
  endDate: 'Date fin',
  destination: 'Destination',
  nbView: 'NB. vues ',
  created_at: 'Date création',
  tracking_target: 'Cible',
  nb_clicks: 'NB. Clics',
  nb_transformation: 'NB. Transformation',
  nb_payments: 'NB. Paiements',
  title_notification: 'Titre notification',
  trigger_date: "Date d'envoi",
  send_count: "Nb d'envoi",
  read_rate: "Taux d'ouverture",
  read_count: "Nb d'ouverture",

  search_popup: 'Rechercher pop-up...',
  no_popup_yet: "Il n'a pas encore des pop-ups",
  no_tracking_link_yet: "Il n'a pas encore des liens de tracking",
  add_popup: 'Ajouter pop-up',
  add_tracking_link: 'Ajouter un lien de tracking',
  search_campaign: 'Rechercher campagne...',
  no_campaign_yet: "Il n'a pas encore des campagnes",
  add_campaign: 'Ajouter une campagne',
  deleteTrackingInfo:
    'Vous souhaitez supprimer ce lien de tracking, si oui confirmer l’action ci-dessous',

  search_notification: 'Rechercher notification...',
  no_notification_yet: "Il n'a pas encore des notifications",
  add_notification: 'Ajouter notification',

  target: {
    users: 'Users',
    coaches: 'Coaches',
    both: 'Users / Coaches',
  },

  // Add form
  add_popup_title: "Planifier pop-up d'ouverture utilisateur / coach",
  update_popup_title: "Réplanifier pop-up d'ouverture utilisateur / coach",
  coaches: 'Coachs',
  users: 'Utilisateurs',
  add_campaign_title: "Planifier une campagne sur la page d'accueil client",
  update_campaign_title: "Réplanifier une campagne sur la page d'accueil client",
  add_notification_title: "Création d'une notification",
  update_notification_title: "N'oubliez pas votre Expert ASC",

  form: {
    title: 'Titre de la campagne',
    titleFr: 'Titre FR',
    titleEn: 'Titre EN',
    title_placeholder: 'Saisir le titre ',
    title_placeholder_fr: 'Saisir le titre',
    title_placeholder_en: 'Enter the title  ',
    boosting_coach_title: 'Coach mis en avant',
    boosting_public_product_title: 'Cours collectif mis en avant',
    boosting_program_title: 'Programme sportifs mis en avant',
    search_coach: 'Rechercher coach',
    content: 'Texte du pop-up',
    contentFr: 'Texte FR',
    contentEn: 'Texte EN',
    content_placeholder: 'Configurer comme vous voulez le pop-up.. ',
    content_placeholder_en: 'Enter the text ... ',
    content_placeholder_fr: 'Saisir le texte ...',
    destination_title: 'A destinations de ?',
    target_title: 'Cette campagne est pour ?',
    date_title: "Planifier l'envoi :",
    image_title: 'Image :',
    Sended: 'Envoyé le ',
    start_date: 'Date de début ',
    end_date: 'Date de fin',

    validation: {
      title_required: 'Le titre est requis',
      title_too_short: 'Le titre est trop court',
      title_too_long: 'Le titre est trop long',
      title_invalid: 'Le titre invalide',

      content_required: 'Le contenu est requis',
    },
  },

  // Delete message popup
  deletePopUpInfo: 'Vous souhaitez supprimer cette popup, si oui confirmer l’action ci-dessous ',
  deleteAllPopUpsInfo:
    'Vous souhaitez supprimer tous les popups, si oui confirmer l’action ci-dessous',
  deleteSelectedPopUpsInfo:
    'Vous souhaitez supprimer les popups sélectionnées, si oui confirmer l’action ci-dessous',

  // Delete message campaign
  deleteCampaignInfo:
    'Vous souhaitez supprimer cette campagne, si oui confirmer l’action ci-dessous ',
  deleteAllCampaignsInfo:
    'Vous souhaitez supprimer tous les campagnes, si oui confirmer l’action ci-dessous',
  deleteSelectedCampaignsInfo:
    'Vous souhaitez supprimer les campagnes sélectionnées, si oui confirmer l’action ci-dessous',

  // Delete message notifications
  deleteNotificationsInfo:
    'Vous souhaitez supprimer cette notification, si oui confirmer l’action ci-dessous ',
  deleteAllNotificationsInfo:
    'Vous souhaitez supprimer tous les notifications, si oui confirmer l’action ci-dessous',
  deleteSelectedNotificationsInfo:
    'Vous souhaitez supprimer les notifications sélectionnées, si oui confirmer l’action ci-dessous',

  deleteAllTrackingLinkInfo:
    'Vous souhaitez supprimer tous les liens de tracking, si oui confirmer l’action ci-dessous',
  deleteSelectedTrackingLinkInfo:
    'Vous souhaitez supprimer les liens de tracking sélectionnées, si oui confirmer l’action ci-dessous',
};
