export const coaches = {
  all_coaches: 'Tous les coachs',
  waiting_coaches: 'Vos Coachs en attente',
  refusal_coach: 'Refus coach',
  all_products: 'Tous les produits',
  no_revenues_yet: "Il n'a pas encore des revenues",
  group_of_coaches: 'Groupe de coach',
  title: 'Coaches',
  subTitle: 'Liste des coachs acceptés',
  waiting_subTitle: 'Liste des coachs en attentes',
  refused_subTitle: 'Liste des coachs refusés',
  search: 'Chercher un coach...',
  coach_name: 'Nom du coach',
  information_personnel: 'Informations personnelles ',
  justificatifs: 'Justificatifs ',
  activities: 'Activités',
  country_of_coach: 'Pays du coach',
  add_degree: 'Ajoutez le diplôme de coach',
  add_card_or_passport: "Ajoutez la carte d'identité ou le passport de coach",
  sports: 'Sports',
  add_sports: 'Ajouter des sports',
  add_sports_message:
    'Veuillez sélectionner les sports dans lesquels ce coach excelle et peut offrir un accompagnement spécialisé ainsi que des conseils sur mesure pour ses clients.',
  all_waiting_coaches: 'Tous vos coachs en attente',
  scheduleSection: 'Votre agenda',
  availabilities: 'Vos disponibilités',
  configure_availabilities: 'Configurez vos disponibilités',
  configure_title: 'Rendez-vous futurs coachs',
  schedule_available_for_reservation:
    'Choisissez les horaires de disponibilités pour les visioconférences :',
  configure_availabilities_description:
    'Déterminez les plages sur lesquelles vous serez disponibles sur les prochains jours pour les visioconférences avec les coachs',
  configure_availabilities_label:
    'Vos coachs peuvent réserver des visioconférences avec vous pour les :',
  modify_availability_date_label:
    'Ajoutez les dates où votre disponibilité change par rapport à vos heures hebdomadaires',
  configuration: 'Configuration disponibilités',
  no_coaches_yet: "Vous n'avez pas encore ajouté d'entraîneurs.",
  no_waiting_coaches: 'Aucun entraîneur en attente.',
  no_refused_coaches: 'Aucun entraîneur refusé.',
  no_coaches_yet_with_alphabet:
    "Vous n'avez pas des noms des entraîneurs qui commencent avec la lettre alphabétique ",
  add_coach: 'Ajouter un coach',
  coach: 'Coach',
  sexe: 'Sexe',
  created_at: 'Date de création',
  inscription_date: "Date d'inscription",
  accepted_at: "Date d'acceptation",
  last_connexion: 'Dernière connexion',
  expert: 'Expert en charge',
  state: 'Etat',
  activity_question:
    'Nous pourrons échanger sur ces points lors de votre entretien avec l’expert ASC',
  select_coach_activities_msg: 'Sélectionner les activités dans lesquelles ce coach excelle',
  required_document: 'Documents requis: ',
  documents_message: `Veuillez fournir la pièce d'identité du coach, ou son passeport, accompagnée de sa carte professionnelle `,
  documents_type: '(format PDF, JPG ou PNG).',
  referral_code_placeholder: 'Ex: ASC-COACH-23084',
  promo_code: 'Un coach vous à parrainer ? Ajouter son code promo',
  question: 'Un coach vous à parrainer ?',
  success_coach_created: 'Coach créé avec succès',
  success_coach_updated: 'Coach modifié avec succès',
  delete_coach_confirmation_msg: "Confirmation de suppression d'un coach",
  delete_all_coaches_msg: 'Confirmation de suppression de tous les coachs',
  delete_selected_coaches_msg: 'Confirmation de suppression des coachs sélectionnés',
  success_coach_deleted: 'Coach supprimé avec succès',
  success_coaches_deleted: 'Coaches supprimés avec succès',
  success_coach_deleted_message: 'Coach a été supprimé avec succès de la base de données.',
  success_coaches_deleted_message: 'Coaches ont été supprimés avec succès de la base de données.',
  coach_suspended: 'Coach a été suspendu avec succès.',
  coach_suspended_message: 'Coach a été suspendu avec succès de la base de données. ',
  coach_reactivated: 'Coach a été reactivé avec succès.',
  coach_reactivated_message: 'Coach a été reactivé avec succès de la base de données. ',
  coaches_suspended: 'Coachs ont été suspendus avec succès.',
  coaches_suspended_message: 'Coachs ont été suspendus avec succès de la base de données.',

  coaches_reactivated: 'Les coachs ont été réactivés avec succès.',
  coaches_reactivated_message: 'Les coachs ont été réactivés avec succès dans la base de données.',
  success_coach_state_updated: 'Statut mis à jour avec succès',
  edit_coach: 'Modifier coach',
  profile: 'Profile du coach',
  suspend: 'Suspendre le coach',
  reactivate: 'Reactiver le coach',
  delete: 'Supprimer le coach',
  coach_bank_details: 'Coordonnées bancaires',
  referralCode_text: 'Code de parrainage:',
  used_by_coaches: "Coachs l'ont utilisés",
  deleteCoachInfo: 'Vous souhaitez supprimer ce coach, si oui confirmer l’action ci-dessous ',
  deleteAllCoachesInfo:
    'Vous souhaitez supprimer tous les coaches, si oui confirmer l’action ci-dessous',
  deleteSelectedCoachesInfo:
    'Vous souhaitez supprimer les coaches sélectionnés, si oui confirmer l’action ci-dessous',
  suspend_coaches:
    'Vous souhaitez suspendre les coaches sélectionnés, si oui confirmer l’action ci-dessous',
  suspend_coach: 'Vous souhaitez suspendre ce coach, si oui confirmer l’action ci-dessous',
  reactivate_coaches:
    'Vous souhaitez réactiver les coaches sélectionnés, si oui confirmer l’action ci-dessous',
  reactivate_coach: 'Vous souhaitez réactiver ce coach, si oui confirmer l’action ci-dessous',
  status: {
    account_to_verify: 'A vérifier',
    account_waiting_for_appointment: 'En attente de rdv',
    account_visio_done: 'Visio finalisée',
    account_missing_documents: 'Pièce manquante',
    account_waiting_reffused: 'Réfusé',
    account_done: 'Terminé',
    account_stripe_created: 'Compte stripe créé',
    account_stripe_in_process_of_validation: 'En cours de validation',
    account_stripe_accepted: 'Accepté',
  },
  coach_not_have_enough_experience: "Le coach n'a pas assez d'experience",
  in_developpment: 'Il est en cours de développement trop tôt',
  high_price: 'Le prix est trop élevé pour lui',
  pursue: "Il n'est souhaite pas poursuivre",
  other_reason: 'Autre',
  confirm_refus_title: 'Confirmer le refus',
  card_validation:
    'veuillez entrer le numéro de la carte ou cocher en cas de préparation en cours.',
  group: {
    name: 'Nom du group',
    status: 'Status',
    no_group_yet: 'Aucun groupe disponible.',
    onLine: 'En ligne',
    offLine: 'Hors ligne',
  },

  // best products
  best_products: 'Meilleurs produits',
  no_best_products_yet: "Il n'a pas encore des produits",
  products: 'Produits',
  qte: 'Qté vendu',
  turnover: 'CA',

  // invoices
  coach_invoices: 'Ses factures ASC',
  stipe_link: 'Lien profil stripe',
  no_invoices_yet: "Il n'a pas encore des factures",
  success_create_stripe_account: 'Compte stripe crée avec succèes',
  not_authorized_to_change_rdv_of_coach_affected_to_other_expert:
    "Vous n'avez pas le droit de traiter le dossier ou replanifier le rendez-vous d'un coach qui est assigné à un autre expert!",
  redirect_coach_message: 'Êtes-vous sûr de vous connecter en tant que coach {{name}} ?',

  reasons: 'Raisons :',
  have_pro_card: 'Le coach possède-t-il un numéro de carte professionnelle ?',
  carte_progress: `Carte pro en cours d'obtention`,
};
