import MediaType from 'types/models/common/MediaType/MediaType';
import { ApiExercise, ApiInstructions, CategoryApiResponse } from './exerciseApi.type';
import { transformApiMediaType } from '../commonTypes/file';
import { BodyPartsEnum } from 'config/enums/body.enum';
import { CreateExercise, Exercise } from 'types/models/Exercise/Exercise';
import { ApiArrayDataResponse, ApiFilter } from 'types/models/ApiResponse';
import { transformApiResponse } from '../BaseApiTransform';

const categoriesTranslationKey = 'filters.categories';
const allCategoriesTranslationKey = 'filters.all_categories';

export const transformCategoriesResponse = (
  response: CategoryApiResponse[],
  withoutDefault?: boolean,
) => {
  const categories = [
    ...response.map((cat) => ({
      id: cat.id,
      label: cat.translated_key
        ? `filters.${cat.translated_key}`
        : cat?.translated_name ?? cat.name,
      value: cat?.subcategories?.map((subCat) => ({
        id: subCat.id,
        label: subCat?.translated_key
          ? `filters.${subCat.translated_key}`
          : subCat?.translated_name ?? subCat.name,
        value: subCat.id,
      })),
    })),
  ];
  return withoutDefault
    ? categories
    : [
        {
          id: 0,
          label: categoriesTranslationKey,
          value: [{ label: allCategoriesTranslationKey, value: 0 }],
        },
        ...categories,
      ];
};

export const transformInstructions = (instructions: ApiInstructions[]) => {
  instructions?.sort((a, b) => a.order - b.order);
  return instructions.map((instruction) => instruction.content);
};

export const transformExercise = (apiExercise: ApiExercise) => {
  const medias: (MediaType | null)[] =
    apiExercise.medias?.map((media) => (media ? transformApiMediaType(media) : null)) ?? [];
  const filteredMedias: MediaType[] = medias.filter((m) => !!m?.src) as MediaType[];
  return {
    id: apiExercise.id,
    name: apiExercise.name,
    photo: transformApiMediaType(apiExercise.photo),
    medias: filteredMedias,
    videoLink: apiExercise.link,
    bodyParts: apiExercise.body_parts?.map((bp) => bp.id) as BodyPartsEnum[],
    advices: apiExercise.instructions ? transformInstructions(apiExercise.instructions) : [],
    bodyZone: apiExercise.body_areas
      ? transformCategoriesResponse(apiExercise.body_areas, true)
      : undefined,
    muscularGroup: apiExercise.muscle_group
      ? transformCategoriesResponse(apiExercise.muscle_group, true)
      : undefined,
    equipments: apiExercise.equipment
      ? transformCategoriesResponse(apiExercise.equipment, true)
      : undefined,
    otherCategories: apiExercise.other_categories
      ? transformCategoriesResponse(apiExercise.other_categories, true)
      : undefined,
  } as Exercise;
};

export const transformExercisesResponse = (response: ApiArrayDataResponse<ApiExercise>) => {
  return {
    ...transformApiResponse(response),
    data: response.data.map((apiEx) => transformExercise(apiEx)),
    categories: response.filters ? transformExercisesFilters(response.filters) : undefined,
  };
};

export const transformCreateExerciseBody = (body: CreateExercise) => {
  return {
    name: body.name,
    instructions: body.advices.map((advice, index) => ({ content: advice, order: index })),
    body_area: body.bodyZone.filter((e) => e),
    body_parts: body.bodyParts.filter((e) => e),
    equipment: body.equipments.filter((e) => e),
    link: body.videoLink,
    photo_id: body.photoId,
    medias_ids: body.medias,
    muscle_group: body.muscularGroup.filter((e) => e),
    other_category: body.otherCategories.filter((e) => e),
  };
};

export const transformExercisesFilters = (filters?: ApiFilter[]) =>
  filters
    ? [
        {
          id: 0,
          label: categoriesTranslationKey,
          value: [{ label: allCategoriesTranslationKey, value: 0 }],
        },
        ...filters.map((filter) => ({
          id: filter.id,
          label: filter.translated_key ? `filters.${filter.translated_key}` : filter.label,
          value: filter.value.map((subFilter) => ({
            label: subFilter.translated_key
              ? `filters.${subFilter.translated_key}`
              : subFilter.label,
            value: subFilter.id ?? subFilter.value,
          })),
        })),
      ]
    : [
        {
          id: 0,
          label: categoriesTranslationKey,
          value: [{ label: allCategoriesTranslationKey, value: 0 }],
        },
      ];
